import Api from "@/api";
import { defineStore } from "pinia";
import { PrimaryKey } from "../interfaces";


export const useFarmerOrganizationStore = defineStore('farmerOrganizationStore',{
    actions:{
        async create(id : PrimaryKey, data : any){
            try{
                const response = await Api.post(`farmer-organization/cluster/${id}`,data)
                return response.data
            }
            catch(error){
                throw error
            }
        },

        async fetchAll(params: any = {}) {
            try{
                const response = await Api.get('farmer-organization', { params });
                return response.data;
            }
            catch(error){
                throw error
            }
           
          },


          async fetchByCluster(id : PrimaryKey, params: any = {}) {
            try{
             
                const response = await Api.get(`farmer-organization/cluster/${id}`, { params });
                return response.data;
            }
            catch(error){
                throw error
            }
           
          },

        async fetchOne(id: string) {
            try {
              const response = await Api.get(`farmer-organization/${id}`);
             console.log("response",response);
             
              return response.data;
            } catch (error: any) {}
          },

        
          async update(data: any, id: string) {
            try {
              const response = await Api.patch(`farmer-organization/${id}`, data);
              return response.data
             
            } catch (error: any) {
                throw error
            }
          },
      
          async delete(id: string) {
            try {
                await Api.delete(`farmer-organization/${id}`);
               
              } catch (error: any) {
                  throw error
              }
          },


    }
})