import { SocketClient } from './api/socket';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './tailwind.css';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import { defineRule, configure } from 'vee-validate';
import * as rules from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';
import customsErrorsMessages from '@/plugins/vee-validate/errors-messages';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
// <<<<<<< HEAD
import './locales';

import VueApexCharts from 'vue3-apexcharts';
import VueGoogleMaps from '@fawmi/vue-google-maps'

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

// =======
// import './locales';
// >>>>>>> feat/backend

//const customsErrorsMessages = require('@/plugins/vee-validate/errors-messages.json');
import customsValidations from './plugins/vee-validate/customs-validations';
import { PhoneInput } from '@lbgm/phone-number-input';

import Toast, { PluginOptions } from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

import { createPinia } from 'pinia';
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';

// import VueSvgIcon, { PluginOptions } from 'vue-svgicon'

// [optional] Default vue-svgicon style, you can use your own.
// source: https://github.com/MMF-FE/vue-svgicon/blob/dev/packages/vue-svgicon/src/style.scss
// import 'vue-svgicon/dist/svgicon.css'

import { VueSvgIconPlugin } from '@yzfe/vue3-svgicon';


new SocketClient();
const app = createApp(App);

app.use(VueSvgIconPlugin, { tagName: 'icon' });
app.use(router).mount('#app');
app.component('EasyDataTable', Vue3EasyDataTable);

app.use(Toast);

app.use(VueApexCharts);

app.use(VueGoogleMaps, {
  load: {
    key:  'AIzaSyD4kFRhFv0I69NXqNWtkEB2-cWLrygOzxI',
    libraries: 'places',
  },
});

//AIzaSyBCfwxk4Rhoe-wSdluMyVJ6xsYI2yFBnOk

configure({
  generateMessage: localize(customsErrorsMessages),
});

configure({
  validateOnInput: true,
});

setLocale('fr');

const requiredComponents = require.context(
  './components/global',
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

requiredComponents.keys().forEach((fileName: string) => {
  const componentConfig = requiredComponents(fileName);

  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        ?.replace(/\.\w+$/, '')
    )
  );
  app.component(componentName, componentConfig.default || componentConfig);
});

const pinia = createPinia();
app.use(pinia);

// Install all rules
Object.keys(rules).forEach((rule: string) => {
  console.log('roles', typeof rules);
  if (rule != 'default')
    // @ts-ignore
    defineRule(rule, rules[rule]);
});

console.log('custom validation', Object.keys(customsValidations));

Object.keys(customsValidations).forEach((rule: string) => {
  // @ts-ignore
  defineRule(rule, customsValidations[rule]);
});
app.use(VCalendar, {});
app.component('PhoneInput', PhoneInput);

app.component('Datepicker', Datepicker);

