import { defineStore } from 'pinia';
import { IContratIntrant,IResponseData } from '@/interfaces/index';
import Api, { HttpException } from '@/api';

export const useContratIntrant = defineStore('contratIntrantStore', {
  state: () => {
    return {
      contrats: [] as Array<IContratIntrant>,
    };
  },

  getters: {
    getAllContrats: (state) => {
      return state.contrats;
    },
    getContrat: (state) => {
      return (contratRef: string) => state.contrats.find((contrat) => contrat.deliveryContract.contract.label == contratRef);
    },
  },

  actions: {
    async create(data: any) {
      try {
        const response = await Api.post(`/contracts/input-advanced-contract`, data);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },
    
    async fetchAll() {
      const response = await Api.get('contracts/input-advanced-contract');
      console.log('response', response.data.data);
      this.contrats = response.data.data;
      //console.log("data", this.contrats);
    },

    async fetchOne(id: string) {
      try {
        const response = await Api.get(`contracts/input-advanced-contract/${id}`);

        console.log(response.data, 'response data');
        return response.data;
      } catch (error: any) {}
    },

    async find(params: any = {}) {
      try {
        const response = await Api.get('contracts/input-advanced-contract', { params });
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },
  },
});
