import { RouteRecordRaw } from "vue-router";
import OPS from "@/views/dashboard/acteurs/ops/index.vue"
import CreateOP from '@/views/dashboard/acteurs/ops/create.vue'
import ShowOP from '@/views/dashboard/acteurs/ops/show.vue'
import { RoleCode, RoleCodeList } from "@/interfaces";
const OPRoutes: RouteRecordRaw[] = [
  {
    path: 'organisations-paysannes',
    name: 'ops',
    component: OPS,
    meta: {
      dashboard: true,
      title: 'Coopérative de Producteurs',
      icon: 'grower-white',
      requiredAuth: true,
      roles: [
        RoleCodeList.ROLE_SUPER_ADMIN,
        RoleCodeList.ROLE_CLUSTER_MANAGER,
        RoleCodeList.ROLE_ADMIN,
        RoleCodeList.ROLE_ADMIN,
         RoleCodeList.ROLE_INDUSTRIAL
      ] as RoleCode[]
    }
  },
  {
    path:'ops/create',
    name: 'createOP',
    component: CreateOP,
    meta:{
      dashboard : true,
      hideNavBar : true,
      requiredAuth: true,
    }
  },
  {
    path:'ops/edit/:id',
    name: 'editOP',
    component: CreateOP,
    meta:{
      dashboard : true,
      hideNavBar : true,
      requiredAuth: true,
    }
  },
  {
    path:'ops/show/:id',
    name: 'showOP',
    component: ShowOP,
    meta:{
      dashboard : true,
      requiredAuth: true,
    }
  }

]

export default OPRoutes