import { RouteRecordRaw } from 'vue-router';

import DemandeIntrant from '@/views/dashboard/demande-intrant/index.vue';


import { RoleCode, RoleCodeList } from '@/interfaces';
const DemandeIntrantsRoutes: RouteRecordRaw[] = [
  {
    path: '/demande',
    name: 'demandeIntrant',
    component: DemandeIntrant,
    meta: {
      dashboard: true,
      title: "Demandes d’intrants",
      icon : 'campagneSC',
      requiredAuth: true,
      roles: [
        RoleCodeList.ROLE_INDUSTRIAL,
        RoleCodeList.ROLE_INPUT_PROVIDER
      ] as RoleCode[],
    },
  },
  // {
  //   path: 'demande/intrant/show/:id',
  //   name: 'showCampagne',
  //   component: CampagneShow,
  //   meta:{
  //     dashboard : true,
  //     requiredAuth: true,
  //     roles: [
  //       RoleCodeList.ROLE_INDUSTRIAL
  //     ] as RoleCode[],
  //   }
  // },
];

export default DemandeIntrantsRoutes;
