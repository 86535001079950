import { RouteRecordRaw } from "vue-router";
import Transporteurs from "@/views/dashboard/acteurs/transporteurs/index.vue"
import CreateTransporteur from '@/views/dashboard/acteurs/transporteurs/create.vue'
import ShowTransporteur from '@/views/dashboard/acteurs/transporteurs/show.vue'
import { RoleCode, RoleCodeList } from "@/interfaces";
const TransporteurRoutes: RouteRecordRaw[] = [
  {
    path: 'transporteurs',
    name: 'transporteurs',
    component: Transporteurs,
    meta: {
      dashboard: true,
      title: 'Transporteurs',
      icon: 'transporteur',
      requiredAuth: true,
      roles: [
        RoleCodeList.ROLE_SUPER_ADMIN,
        RoleCodeList.ROLE_CLUSTER_MANAGER,
        RoleCodeList.ROLE_ADMIN,
         RoleCodeList.ROLE_INDUSTRIAL
      ] as RoleCode[]
    }
  },
  {
    path:'transporteurs/create',
    name: 'createTransporteur',
    component: CreateTransporteur,
    meta:{
      dashboard : true,
      hideNavBar : true,
      requiredAuth: true,
    }
  },
  {
    path:'transporteurs/edit/:id',
    name: 'editTransporteur',
    component: CreateTransporteur,
    meta:{
      dashboard : true,
      hideNavBar : true,
      requiredAuth: true,
    }
  },
  {
    path:'transporteurs/show/:id',
    name: 'showTransporteur',
    component: ShowTransporteur,
    meta:{
      dashboard : true,
      requiredAuth: true,
    }
  }

]

export default TransporteurRoutes