import { RouteRecordRaw } from "vue-router";
import EmptyLayout from "@/layouts/empty.vue";
import PaiementIndex from "@/views/dashboard/transactions/paiements/index.vue";
import PaiementShow from "@/views/dashboard/transactions/paiements/show.vue";
import ApprovisionnementIndex from "@/views/dashboard/transactions/approvisionnements/index.vue";
import AdminApprovisionnementIndex from "@/views/admin/transactions/approvisionnements/index.vue";
import FinancementIndex from "@/views/dashboard/transactions/financements/index.vue";
import { RoleCode, RoleCodeList } from "@/interfaces";

const TransactionRoutes: RouteRecordRaw = {
  path: "transactions",
  meta: {
    title: "Transactions",
    dashboard: true,
    submenu: true,
    requiredAuth: true,
    tag: [
      RoleCodeList.ROLE_SUPER_ADMIN,
      RoleCodeList.ROLE_ADMIN,
      RoleCodeList.ROLE_INDUSTRIAL,
      RoleCodeList.ROLE_INPUT_PROVIDER,
      // RoleCodeList.ROLE_SFD,
      // RoleCodeList.ROLE_PROVIDER
    ] as RoleCode[],
  },
  component: EmptyLayout,
  children: [
    {
      path: "paiementsEffectues",
      name: "paiementsEffectues",
      component: PaiementIndex,
      meta: {
        dashboard: true,
        title: "Paiements effectués",
        icon: "paiementEffectue",
        requiredAuth: true,
        roles: [
          RoleCodeList.ROLE_ADMIN,
          RoleCodeList.ROLE_INDUSTRIAL,
          RoleCodeList.ROLE_INPUT_PROVIDER,

          // RoleCodeList.ROLE_PROVIDER,
        ] as RoleCode[],
      },
    },
    {
      path: "paiementsEffectues/:id/show",
      name: "paiementShow",
      component: PaiementShow,
      meta: {
        dashboard: true,
        requiredAuth: true,
      },
    },
    {
      path: "approvisionnements",
      name: "approvisionnements",
      component: ApprovisionnementIndex,
      meta: {
        dashboard: true,
        title: "Approvisionnements",
        icon: "approvisionnement",
        requiredAuth: true,
        roles: [
          RoleCodeList.ROLE_ADMIN,
          RoleCodeList.ROLE_INDUSTRIAL,
          // RoleCodeList.ROLE_SFD,
        ] as RoleCode[],
      },
    },
    {
      path: "/financements",
      name: "financement",
      component: FinancementIndex,
      meta: {
        dashboard: true,
        title: "Financement",
        icon: "approvisionnement",
        requiredAuth: true,
        roles: [
          // RoleCodeList.ROLE_SFD,
          RoleCodeList.ROLE_INDUSTRIAL,
        ] as RoleCode[],
      },
    },
    {
      path: "admin/approvisionnements",
      name: "admin-approvisionnements",
      component: AdminApprovisionnementIndex,
      meta: {
        dashboard: true,
        title: "Approvisionnements",
        icon: "approvisionnement",
        requiredAuth: true,
        roles: [RoleCodeList.ROLE_SUPER_ADMIN] as RoleCode[],
      },
    },
    // {
    //   path: "/financements",
    //   name: "financement",
    //   component: FinancementIndex,
    //   meta: {
    //     dashboard: true,
    //     title: "Financement",
    //     icon: "approvisionnement",
    //     requiredAuth: true,
    //     roles: [
    //       RoleCodeList.ROLE_SFD,
    //       RoleCodeList.ROLE_INDUSTRIAL,
    //     ] as RoleCode[],
    //   },
    // },
  ],
};

export default TransactionRoutes;
