import { defineStore } from 'pinia';
import { IRole } from '@/interfaces';
import Api from '@/api';

export const useReportingStore = defineStore('reportingStore', {
 

  actions: {
    async growersStats(data:any) {
      const response = await Api.get('reporting/growers-stats',{params:data});
      return response.data;
    },

    async transactionsStats(data:any) {
        const response = await Api.get('reporting/transactions-stats',{params:data});
        return response.data;
      },
  },
});
