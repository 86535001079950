import { RouteRecordRaw } from "vue-router";
import EmptyLayout from '@/layouts/empty.vue'
import ContratLivraison from '@/views/dashboard/contrats/livraison/index.vue'

import CreateContratTransport from '@/views/dashboard/contrats/transport/create.vue'
import CreateContratIntrant from '@/views/dashboard/contrats/intrant/create.vue'

import ShowContratTransport from '@/views/dashboard/contrats/transport/show.vue'
import ShowContratIntrant from '@/views/dashboard/contrats/intrant/show.vue'
import ShowContratLivraison from '@/views/dashboard/contrats/livraison/show.vue'




import ContratTransport from '@/views/dashboard/contrats/transport/index.vue'
import ContratIntrant from '@/views/dashboard/contrats/intrant/index.vue'
import ContratPrestation from '@/views/dashboard/contrats/prestation/index.vue'
import ContratLivraisonMany from '@/views/dashboard/contrats/livraison/create-many.vue'
import ContratLivraisonSingle from '@/views/dashboard/contrats/livraison/create-single.vue'
import ShowUploadProducteurs from '@/views/dashboard/contrats/livraison/show-upload-producteurs.vue'
import ContratLivraisonFile from '@/views/dashboard/contrats/livraison/create-productors-file.vue'
import { RoleCode, RoleCodeList } from "@/interfaces";

import ContratList from "@/views/dashboard/contrats/index.vue"
import { IContractType } from "@/store/contrats";


const ContratRoutes: RouteRecordRaw = {
  path: 'contrats',
  meta:{
    title : 'Contrats',
    dashboard : true,
    submenu : true,
    requiredAuth: true,
    tag: [
      RoleCodeList.ROLE_ADMIN,
      RoleCodeList.ROLE_INDUSTRIAL
    ] as RoleCode[]
  },
  component: EmptyLayout,
  children: [


   


    {
      path:'livraison',
      name: 'contratsLivraisons',
      component: ContratList,
      meta:{
        dashboard : false,
        title : 'Contrats de livraison',
        icon : 'contrat',
        requiredAuth: true,
        type : 'LIVRAISON',
        roles: [
          RoleCodeList.ROLE_SUPER_ADMIN,
          RoleCodeList.ROLE_ADMIN,
          RoleCodeList.ROLE_INDUSTRIAL
        ] as RoleCode[]
      }
    },

    {
      path:'livraison/:id',
      name: 'sousContratsLivraisons',
      component: ContratList,
      meta:{
        dashboard : true,
        icon : 'contrat',
        requiredAuth: true,
        type : 'LIVRAISON',
        roles: [
          RoleCodeList.ROLE_SUPER_ADMIN,
          RoleCodeList.ROLE_ADMIN,
          RoleCodeList.ROLE_INDUSTRIAL
        ] as RoleCode[]
      }
    },

    {
      path:'avance-ananas/:id',
      name: 'sousContratsAvancesAnanas',
      component: ContratList,
      meta:{
        dashboard : true,
        icon : 'contrat',
        requiredAuth: true,
        type : IContractType.AVANCE_SUR_ANANAS,
        roles: [
          RoleCodeList.ROLE_SUPER_ADMIN,
          RoleCodeList.ROLE_ADMIN,
          RoleCodeList.ROLE_INDUSTRIAL
        ] as RoleCode[]
      }
    },



    // {
    //   path:'intrants',
    //   name: 'contratsAvancesIntrants',
    //   component: ContratList,
    //   meta:{
    //     dashboard : true,
    //     title : 'Contrats d’avance sur intrant',
    //     icon : 'contrat',
    //     requiredAuth: true,
    //     type: IContractType.AVANCE_SUR_INTRANT,
    //     roles: [
    //       RoleCodeList.ROLE_SUPER_ADMIN,
    //       RoleCodeList.ROLE_ADMIN,
    //       RoleCodeList.ROLE_INDUSTRIAL
    //     ] as RoleCode[]
    //   }
    // },
    {
      path:'fourniture',
      name: 'contratsFournituresIntrants',
      component: ContratList,
      meta:{
        dashboard : true,
        title : "Contrats de fournitures d'intrants",
        icon : 'contrat',
        requiredAuth: true,
        type: IContractType.FOURNITURE_INTRANT,
        roles: [
          RoleCodeList.ROLE_SUPER_ADMIN,
          RoleCodeList.ROLE_ADMIN,
          RoleCodeList.ROLE_INDUSTRIAL
        ] as RoleCode[]
      }
    },


    // {
    //   path:'financement-social',
    //   name: 'contratsFinancementsSocials',
    //   component: ContratList,
    //   meta:{
    //     dashboard : true,
    //     title : 'Contrat de financement social',
    //     icon : 'contrat',
    //     requiredAuth: true,
    //     type: IContractType.FINANCEMENT_SOCIAL,
    //     roles: [
    //       RoleCodeList.ROLE_SUPER_ADMIN,
    //       RoleCodeList.ROLE_ADMIN,
    //       RoleCodeList.ROLE_INDUSTRIAL
    //     ] as RoleCode[]
    //   }
    // },


    {
      path:'avance-ananas',
      name: 'contratsAvancesAnanas',
      component: ContratList,
      meta:{
        dashboard : true,
        title : 'Contrat avance sur ananas',
        icon : 'contrat',
        requiredAuth: true,
        type: IContractType.AVANCE_SUR_ANANAS,
        roles: [
          RoleCodeList.ROLE_SUPER_ADMIN,
          RoleCodeList.ROLE_ADMIN,
          RoleCodeList.ROLE_INDUSTRIAL
        ] as RoleCode[]
      }
    },

   

    
    {
      path:'livraison/create/single/:contratId/:name',
      name: 'createContratLivraisonSingle',
      component: ContratLivraisonSingle,
      meta:{
        dashboard : true,
        hideNavBar : true,
        requiredAuth: true,
      }
    },
    {
      path:'livraison/create/many/:name',
      name: 'createContratLivraisonMany',
      component: ContratLivraisonMany,
      meta:{
        dashboard : true,
        requiredAuth: true,
      }
    },
    {
      path:'livraison/create/many/:name/producteurs',
      name: 'showUploadProducteurs',
      component: ShowUploadProducteurs,
      meta:{
        dashboard : true,
        requiredAuth: true,
      }
    },
    {
      path:'livraison/create/producteurs/file/:name',
      name: 'createContratLivraisonFile',
      component: ContratLivraisonFile,
      meta:{
        dashboard : true,
        requiredAuth: true,
      }
    },
    {
      path:'livraison/show/:id',
      name: 'ShowContratLivraison',
      component: ShowContratLivraison,
      meta:{
        dashboard : true,
        requiredAuth: true,
      }
    },
    // {
    //   path:'transport',
    //   name: 'contratsTransports',
    //   component: ContratTransport,
    //   meta:{
    //     dashboard : true,
    //     title : 'Contrats de transport',
    //     icon : 'contrat',
    //     requiredAuth: true,
    //     roles: [
    //       RoleCodeList.ROLE_SUPER_ADMIN,
    //       RoleCodeList.ROLE_ADMIN,
    //       RoleCodeList.ROLE_INDUSTRIAL
    //     ] as RoleCode[]
    //   }
    // },
    // {
    //   path:'transport/create',
    //   name: 'CreateContratTransport',
    //   component: CreateContratTransport,
    //   meta:{
    //     dashboard : true,
    //     hideNavBar : true,
    //     requiredAuth: true,
    //   }
    // },
    // {
    //   path:'transport/show/:id',
    //   name: 'ShowContratTransport',
    //   component: ShowContratTransport,
    //   meta:{
    //     dashboard : true,
    //     requiredAuth: true,
    //   }
    // },
  
   
   
    {
      path:'avance/show/:id',
      name: 'ShowContratIntrant',
      component: ShowContratIntrant,
      meta:{
        dashboard : true,
        requiredAuth: true,

      }
    },
    // {
    //   path:'prestation',
    //   name: 'contratsPrestations',
    //   component: ContratPrestation,
    //   meta:{
    //     dashboard : true,
    //     title : 'Contrats de prestation',
    //     icon : 'contrat',
    //     requiredAuth: true,
    //   }
    // },
  ]
}

export default ContratRoutes;