<template>
  <Datepicker v-model="year" yearPicker />
</template>

<script>
import { ref, watch, onMounted } from "vue";

export default {
  emits: ["year"],
  setup(props, ctx) {
    const year = ref(new Date().getFullYear());
    ctx.emit("year", year);
    watch(year, (newYear) => {
      ctx.emit("year", newYear);

      console.log('new year', newYear);
    });
    return {
      year,
    };
  },
};
</script>
