import { RouteRecordRaw } from "vue-router";
import EmptyLayout from '@/layouts/empty.vue'

import CooperativeRoutes from '../../dashboard/acteurs/cooperative'
import ProducteurRoutes from "../../dashboard/acteurs/producteurs";
import TransporteurRoutes from "../../dashboard/acteurs/transporteurs";
import FournisseurIntrantRoutes from "../acteurs/fournisseurs-intrant.ts";
import { RoleCode, RoleCodeList } from "@/interfaces";

const PrestatairesRoutes: RouteRecordRaw = 
  {
    path: 'prestataires',
    name: 'prestataires',
    meta: {
      dashboard: true,
      title: 'Prestataires',
      icon: 'prestataire',
      submenu: true,
      requiredAuth: true,
      tag: [
        RoleCodeList.ROLE_ADMIN,
        RoleCodeList.ROLE_SUPER_ADMIN,
        RoleCodeList.ROLE_CLUSTER_MANAGER,
        // RoleCodeList.ROLE_INDUSTRIAL
      ] as RoleCode[]
    },
    component: EmptyLayout,
    children: [
      // ...ProducteurRoutes,
      // ...CooperativeRoutes,
      // ...TransporteurRoutes,
      ...FournisseurIntrantRoutes
    ]
  }



export default PrestatairesRoutes