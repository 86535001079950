import { defineStore } from "pinia";
import Api, { HttpException } from "@/api";
import data from "@/data";
import { AxiosPromise } from "axios";
import {
  ICooperative,
  IOrganization,
  IOrganizationTypeList,
  PrimaryKey,
  SendInvitationForm,
} from "@/interfaces";

interface Zone {
  longitude: number;
  latitude: number;
  label: string;
}

interface mZone {
  id: string;
  data: {
    coordinates?: Array<Record<string, unknown>>;
    name: string;
  };
}

export const useCoverZone = defineStore("coverZoneStore", {
  state: () => {
    return {
      coverzones: [] as Array<any>,
      fetch_loading: false as boolean,
    };
  },

  actions: {
    randId(): string {
      return "d2d_" + Math.random().toString(36).substr(2, 9);
    },

    // async getZones(payload?: any): Promise<AxiosPromise> {
    //     this.fetch_loading = true
    //     try {
    //         const response = await Api.get(`users`, { params: { ...payload } })
    //         this.coverzones = response.data
    //         this.fetch_loading = false
    //         return response.data
    //     } catch (error) {
    //         throw(error)
    //     }
    //   },

    async addZone(id: PrimaryKey, payload: any): Promise<AxiosPromise> {
      try {
        const response = await Api.post(`users/add-site/${id}`, payload);
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    async updateZone(siteId: PrimaryKey, payload: any): Promise<AxiosPromise> {
      try {
        const response = await Api.patch(
          `users/update-site/${siteId}`,
          payload
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    async deleteZone(siteId: PrimaryKey) {
      try {
        const response = await Api.delete(`users/delete-site/${siteId}`);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  },
});
