import { defineStore } from 'pinia';
import { IContratTransport, IResponseData } from '@/interfaces/index';
import Api, { HttpException } from '@/api';

export const useContratTransport = defineStore('contratTransportStore', {
  state: () => {
    return {
      contrats: [] as Array<IContratTransport>,
    };
  },
  getters: {
    getAllContrats: (state) => {
      return state.contrats;
    },
    getContrat: (state) => {
      console.log("contrat contrat",state.contrats)
      return (contratRef: string) => state.contrats.find((contrat) => contrat.deliveryContract!.contract.label == contratRef);
    },
  },

  actions: {
    async create(data: any) {
      console.log('data ---> ', data);
      
      try {
        const response = await Api.post(`contracts/carriage-contract`, data);
        return response.data;
      } catch (error: any) {
        console.log('create transport contract error ---> ', error);
        throw new HttpException(error);
      }
    },
    async fetchAll(params: any = {}) {
      const response = await Api.get("contracts/carriage-contract",{ params });
      console.log('response fetching', response.data.data);
      this.contrats = response.data.data;
      //console.log("data", this.contrats);
    },

    async fetchOne(id: string) {
      try {
        const response = await Api.get(`contracts/carriage-contract/${id}`);

        console.log(response.data, 'response data');
        return response.data;
      } catch (error: any) {}
    },

    async find(params: any = {}) {
      try {
        const response = await Api.get('contracts/carriage-contract', { params });
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },
  },
});
