import Api, { HttpException } from '@/api';
import { CreateOrganizatonForm, ICooperative, IErrorData, IOrganization, IUser, PrimaryKey, SendInvitationForm } from '@/interfaces';
import { defineStore } from 'pinia';
import { authStore } from './auth-store';

export const useUsersStore = defineStore('users', {
  state: () => ({
    users: [] as IUser[],
    currentUser: null as IUser | null,
    currentOrganization: null as IOrganization | null,
    cooperatives: [] as ICooperative[],
  }),

  getters: {
    getCurrentUser: (state) => {
      return state.currentUser;
    },

    getTokenInformations: (state) => {},
  },

  actions: {
    async create(user: IUser) {
      const useAuthStore = authStore()
      try {
        const response = await Api.post(`/users/invitation/signup?typeUser=${'user'}`, {
          ...user,
          username: user.email,
          walletId: useAuthStore.auth.getWalletId(),
          organizationId: useAuthStore.auth.getOrganizationId(),
        });
        return {
          isOk: true,
          message: '',
        };
      } catch (error: any) {
        let errorData: IErrorData = {
          isOk: false,
          message: 'Une erreur inattendue est survenue',
        };
        switch (error.response.status) {
          case 409:
            if (error.response.data.reason == 'USER_ALREADY_EXIST') errorData.message = 'Ce utilisateur existe déjà';
        }
        return errorData;
      }
    },

    async update(id: PrimaryKey, data: Partial<IUser> = {}) {
      try {
        const response = await Api.patch(`/users/${id}`, data);
        return {
          isOk: true,
          message: '',
        };
      } catch (error: any) {
        let errorData: IErrorData = {
          isOk: false,
          message: 'Une erreur inattendue est survenue',
        };

        return errorData;
      }
      // const index = this.users.findIndex(user => user.id === id);
      // const user = this.users.find(user => user.id === id);

      // this.users.splice(index, 1, {...user, ...data} as IUser);
    },

    async delete(id: string) {
      try {
        const response = await Api.delete(`/users/${id}`);
        this.users = this.users.filter((user) => user.id != id);
        return {
          isOk: true,
          message: '',
        };
      } catch (error: any) {
        let errorData: IErrorData = {
          isOk: false,
          message: 'Une erreur inattendue est survenue',
        };
        return errorData;
      }
    },

    async fetchAll() {
      const useAuthStore = authStore()
      const response = await Api.get('users');
      this.users = response.data;
    },

    async findOne(id: any, saveAsCurrentUser: boolean = false) {
      const response = await Api.get(`users/${id}`);
      console.log('findOne response =>', response);
      if (saveAsCurrentUser) this.currentUser = response.data;
      return response.data;
    },

    async getStats(params = {}) {
      try {
        const response = await Api.get('contracts/stats', { params });
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async sendInvitation(data: SendInvitationForm | CreateOrganizatonForm, type: string) {
      try {
        const response = await Api.post(`/users/invitation/signup?typeUser=${type}`, data)
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },
  },
});
