import { RouteRecordRaw } from 'vue-router';

import DemandeFinancementSocial from '@/views/dashboard/demande-financement/demande-financement-social.vue'
import DemandeAvanceAnanas from '@/views/dashboard/demande-financement/demande-avance-ananas.vue'
import DemandeIntrant from '@/views/dashboard/demande-financement/demande-intrant.vue'
import DemandeOP from '@/views/dashboard/demande-financement/index.vue'


import { RoleCode, RoleCodeList } from '@/interfaces';
const DemandeFinancementRoutes: RouteRecordRaw[] = [
  {
    path: 'op/demande/intrant',
    name: 'demandesIntrants',
    component: DemandeOP,
    meta: {
      dashboard: true,
      title: "Demandes d’intrants",
      type : "INPUT",
      icon : 'campagneSC',
      requiredAuth: true,
      roles: [
        RoleCodeList.ROLE_INDUSTRIAL,
        RoleCodeList.ROLE_FARMER_ORGANIZATION
      ] as RoleCode[],
    },
  },
  // {
  //   path: '/demande/financement-sociale',
  //   name: 'demandeFinancementSocial',
  //   component: DemandeOP,
  //   meta: {
  //     dashboard: true,
  //     title: "Demandes de financements social",
  //     type : "SOCIAL",
  //     icon : 'campagneSC',
  //     requiredAuth: true,
  //     roles: [
  //       RoleCodeList.ROLE_INDUSTRIAL,
  //       RoleCodeList.ROLE_FARMER_ORGANIZATION
  //     ] as RoleCode[],
  //   },
  // },

 {
    path: '/demande/avance-ananas',
    name: 'demandeAvanceAnanas',
    component: DemandeOP,
    meta: {
      dashboard: true,
      title: "Demandes de avances sur ananas",
      type : "PINEAPLE",
      icon : 'campagneSC',
      requiredAuth: true,
      roles: [
        RoleCodeList.ROLE_INDUSTRIAL,
        RoleCodeList.ROLE_FARMER_ORGANIZATION
      ] as RoleCode[],
    },
  },


  // {
  //   path: '/op/demande/intrant',
  //   name: 'demandeOP',
  //   component: DemandeOP,
  //   meta: {
  //     dashboard: true,
  //     type : "INPUT",
  //     title: "Demandes d'intrantss",
  //     icon : 'campagneSC',
  //     requiredAuth: true,
  //     roles: [
  //       RoleCodeList.ROLE_INDUSTRIAL,
  //       RoleCodeList.ROLE_FARMER_ORGANIZATION
  //     ] as RoleCode[],
  //   },
  // },

  


  

  // {
  //   path: '/demande/avance-ananas/:id',
  //   name: 'demandeAvanceAnanas',
  //   component: DemandeAvanceAnanas,
  //   meta: {
  //     dashboard: true,
  //     title: "Demandes de avances sur ananas",
  //     icon : 'campagneSC',
  //     requiredAuth: true,
  //     roles: [
  //       RoleCodeList.ROLE_INDUSTRIAL,
  //       RoleCodeList.ROLE_FARMER_ORGANIZATION
  //     ] as RoleCode[],
  //   },
  // },
];

export default DemandeFinancementRoutes;
