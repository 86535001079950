import { AxiosError } from 'axios';

export default class HttpException extends AxiosError {
  public error: AxiosError;
  constructor(error: AxiosError) {
    super();
    let message = 'Une erreur inattendue est survenue';
    if (error.response) {
      this.response = error.response;
      let data: any;
      switch (error.response.status) {
        case 401:
          message = 'Not authenticated';
          break;
        case 422:
          data = error.response.data;
          if (data.message) {
            message = data.message;
          }
          break;
        case 409:
          data = error.response.data;
          if (data.message) {
            message = data.message;
          }
          break;

        default:
          break;
      }
      this.message = message;
    } else {
      this.message = error.message ? error.message : message;
    }
    this.error = error;
  }
}
