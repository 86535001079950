import Api, { HttpException } from '@/api';
import { ICooperative, IProducteur } from '@/interfaces';
import { defineStore } from 'pinia';

export const campagneStore = defineStore('campagne', {
  state: () => {
    return {
      cooperatives: [] as ICooperative[],
      growers: [] as IProducteur[],
    };
  },

  actions: {
    async create(data: any) {
      try {
        const response = await Api.post(`/clusters`, data);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

   

    async getCampaign(id : string){
      try {
        const response = await Api.get(`/clusters/${id}`);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },


    async activateCampaign(id : string){
      try {
        const response = await Api.post(`/clusters/activate-cluster/${id}`);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getIndustrialCampaigns(id: string) {
      try {
        const response = await Api.get(`/clusters/industrial/${id}`);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getIndustrialAskingRequest(industrialId : string,search:string | null){
      try {
        let response
        let params = {limit : 100,search:""}
        if(search)
          params['search'] = search
        response = await Api.get(`/funding/industrials-asking-request/${industrialId}`,{
            params
          });
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async industrialAcceptProductorRequest(demandeId : string){
      try {
        const response = await Api.post(`/funding/accept-funding-request/${demandeId}`);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getInactiveIndustrial() {
      try {
        const response = await Api.get(`/users/count-inactives`);
        console.log("--active--",response.data.value)
        console.log("--active1--",response.data.data)
        // console.log("active industriel industriel",response.data.value)
        return response.data.value;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async activateIndustrialCampaign(id: string) {
      try {
        const response = await Api.get(`/clusters/${id}/activate`);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async validateIndustrialCampaign(id: string) {
      try {
        const response = await Api.get(`/clusters/${id}/validate`);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    //tout les industriels d'une campagne
    async getCampaignIndustrials(id: number|string) {
      try {
        const response = await Api.get(`/clusters/${id}/linked-industrials`);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async setupIndustrialQte(
      id: number |string,
      data: {
        industrialId: string;
        quantity: number;
      }
    ) {
      try {
        const response = await Api.post(`/clusters/${id}/industrial`, data);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },



    //supprimer un industriel lié à la campagne
    async deleteCampaignIndustrial(id: string) {
      try {
        const response = await Api.delete(
          `/clusters/delete-industrial-link/${id}`
        );
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async update(data: any, id: string) {
      try {
        const response = await Api.patch(`/clusters/${id}`, data);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async delete(id: string) {
      try {
        const response = await Api.delete(`/clusters/${id}`);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async find(config: any = {}) {
      try {
        const response = await Api.get('/clusters', config);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getInputProviderMaxQuantity(options = {}){
      try{
        const response = await Api.get('user-cluster-input-provider/input-quantite',{
          params : options
        })
        return response.data
      }
      catch(error: any){
        throw new HttpException(error)
      }
    },


    async addInputProviderToCampagne(data = {}){
      try{
        const response = await Api.post('user-cluster-input-provider',data)
        return response.data
      }
      catch(error: any){
        throw new HttpException(error)
      }
    },

    async removeInputProviderToCampagne(id : string){
      try{
        const response = await Api.delete(`user-cluster-input-provider/${id}`)
        return response.data
      }
      catch(error: any){
        throw new HttpException(error)
      }
    },

    async getCampagneInputProvider(id : string){

      try{
        const response = await Api.get(`user-cluster-input-provider/${id}/input-providers`)
        return response.data
      }
      catch(error: any){
        throw new HttpException(error)
      }

    },


    async getFertilizerTypes(){
      try{
        const response = await Api.get(`clusters/fertilizer-types`)
        return response.data
      }
      catch(error: any){
        throw new HttpException(error)
      }
    },

    async getFertilizersForScpecificType(typeId : string){
      try{
        const response = await Api.get(`clusters/fertilizer-types/${typeId}`)
        return response.data
      }
      catch(error: any){
        throw new HttpException(error)
      }
    } ,


    async getActiveFertilizerTypes(){
      try{
        const response = await Api.get(`clusters/active-fertilizers-types`)
        return response.data
      }
      catch(error: any){
        throw new HttpException(error)
      }
    },

    async getActiveFertilizersForScpecificType(typeId : string){
      try{
        const response = await Api.get(`clusters/active-fertilizers/${typeId}`)
        return response.data
      }
      catch(error: any){
        throw new HttpException(error)
      }
    } 

    
  },
});

