<template>
  <router-view class="h-screen w-screen" id="app" />
</template>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');

  #app {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 20px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .vue-map-container {
  max-width: 700px;
  height: 400px !important;
  width: 100%;

  @media screen and (max-width: 1000px) {
    height: 300px !important;
  }
}

</style>
