import { RouteRecordRaw } from "vue-router";

import CampagneIndex from "@/views/admin/campaign/index.vue";
import CampagneIndexInd from "@/views/admin/campaign/indexIndustriel.vue";
import CampagneShow from "@/views/admin/campaign/showCampagne.vue";
import CampagneDetail from "@/views/admin/campaign/detail.vue";
import EmptyLayout from '@/layouts/empty.vue'
import CampagneCreate from "@/views/admin/campaign/create.vue";

import { RoleCode, RoleCodeList } from "@/interfaces";





const CampagneRoutes: RouteRecordRaw = 

{
  path: "saison",
  component: EmptyLayout,
  // name: 'dashboard',
  meta: {
    title: "Activités",
    submenu: true,
    requiredAuth: true,
    tag: [
      RoleCodeList.ROLE_SUPER_ADMIN,
      RoleCodeList.ROLE_CLUSTER_MANAGER,
      RoleCodeList.ROLE_INDUSTRIAL,
    ] as RoleCode[],
  },
  children: [
    
      {
        path: "",
        name: "admin-saison",
        component: CampagneIndex,
        meta: {
          dashboard: true,
          title: "Saisons",
          icon: "campagneSC",
          requiredAuth: true,
          roles: [
            RoleCodeList.ROLE_SUPER_ADMIN,
            RoleCodeList.ROLE_CLUSTER_MANAGER
            // RoleCodeList.ROLE_INDUSTRIAL
          ] as RoleCode[],
        },
      },
      {
        path: "show/:id",
        name: "detailCampagne",
        component: CampagneDetail,
        meta: {
          dashboard: true,
          requiredAuth: true,
          roles: [RoleCodeList.ROLE_INDUSTRIAL] as RoleCode[],
        },
      },
      {
        path: "/dashboard/industriel/saisons",
        name: "industrial-saison",
        component: CampagneIndexInd,
        meta: {
          dashboard: true,
          title: "Financement de la saison",
          icon: "campagneSC",
          requiredAuth: true,
          roles: [RoleCodeList.ROLE_INDUSTRIAL] as RoleCode[],
        },
      },
      {
        path: "/dashboard/industriel/saison/:id",
        name: "showCampagne",
        component: CampagneShow,
        meta: {
          dashboard: true,
          requiredAuth: true,
          roles: [RoleCodeList.ROLE_INDUSTRIAL] as RoleCode[],
        },
      },
      {
        path: "create",
        name: "admin-saison-create",
        component: CampagneCreate,
        meta: {
          dashboard: true,
          hideNavBar: true,
          requiredAuth: true,
        },
      },
    ]
}




export default CampagneRoutes;
