import { RouteRecordRaw } from "vue-router";
import Prestataires from "@/views/dashboard/acteurs/prestataires/index.vue"
import CreateFournisseurIntrant from '@/views/dashboard/acteurs/prestataires/create.vue'
import ShowFournisseurIntrant from '@/views/dashboard/acteurs/prestataires/show.vue'
import { RoleCode, RoleCodeList } from "@/interfaces";

const FournisseursIntrantRoutes: RouteRecordRaw[] = [
  {
    path: 'fournisseurs-intrant',
    name: 'fournisseursIntrant',
    component: Prestataires,
    meta: {
      dashboard: true,
      title: "Fournisseurs d'intrants",
      icon: 'prestataire',
      requiredAuth: true,
      roles: [
        RoleCodeList.ROLE_SUPER_ADMIN,
        RoleCodeList.ROLE_CLUSTER_MANAGER,
        RoleCodeList.ROLE_ADMIN,
        RoleCodeList.ROLE_ADMIN,
        RoleCodeList.ROLE_INDUSTRIAL
      ] as RoleCode[]
    }
  },
  {
    path:'fournisseurs-intrant/create',
    name: 'createFournisseurIntrant',
    component: CreateFournisseurIntrant,
    meta:{
      dashboard : true,
      hideNavBar : true,
      requiredAuth: true,
    }
  },
  
  {
    path:'fournisseurs-intrant/show/:id',
    name: 'showFournisseurIntrant',
    component: ShowFournisseurIntrant,
    meta:{
      dashboard : true,
      requiredAuth: true,
    }
  },

  {
    path:'fournisseurs-intrant/update/:id',
    name: 'updateFournisseurIntrant',
    component: CreateFournisseurIntrant,
    meta:{
      dashboard : true,
      requiredAuth: true,
      hideNavBar : true,
    }
  },

]

export default FournisseursIntrantRoutes