import { defineStore } from 'pinia';
import RoleData from '@/data/roles.json';
import { IRole } from '@/interfaces';
import Api from '@/api';

export const useRoleStore = defineStore('role', {
  state: () => {
    const roles: IRole[] = [];
    return {
      roles: roles,
    };
  },

  actions: {
    async fetchAll() {
      const response = await Api.get('role');
      return response.data;
    },
  },
});
