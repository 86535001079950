import { defineStore } from 'pinia';
import allProducteurs from '@/data/producteurs.json';
import { IErrorData, IProducteur, IUser, PrimaryKey } from '@/interfaces/index';
import Api, { HttpException } from '@/api';


export const useProduct = defineStore('productStore', {
 
  actions: {
  
    async update(data: any, id: PrimaryKey) {
      try {
        const response = await Api.patch(`product/${id}`, data);
        //this.producteurs = response.data;
        return {
          isOk: true,
          message: '',
        };
      } catch (error: any) {
        
        let errorData: IErrorData = {
          isOk: false,
          message: error.message,
        };
       
        return errorData;
      }
    },

    async create(data: any) {
      try {
        const response = await Api.post(`product`, data);
       
        return {
          isOk: true,
          message: '',
        };
      } catch (error: any) {
        
        throw new HttpException(error);
      }
    }

   
  },
});
