import { defineStore } from 'pinia';
import { IErrorData, ITransporteur, IUser } from '@/interfaces/index';
import Api from '@/api';
import { useUsersStore } from './users/user-store';

export const useTransporteur = defineStore('transporteurStore', {
  state: () => {
    return {
      transporteurs: [] as ITransporteur[],
    };
  },
  getters: {
    getAllTransporteurs: (state) => {
      return state.transporteurs;
    },

    // getProducteur: (state) => {
    //   return (producteurReference: string) =>
    //     state.transporteurs.find(
    //       (producteur) =>
    //         producteur.personalInfo.reference == producteurReference
    //     );
    // },
  },

  actions: {
    async fetchAll(params: any = {}) {
      const response = await Api.get('carrier', { params });
      this.transporteurs = response.data.data;
    },

    async fetchOne(id: string) {
      try {
        const response = await Api.get(`carrier/${id}`);

        console.log(response.data, 'response data');
        return response.data;
      } catch (error: any) {}
    },

    async create(data: any) {
      console.log('data', data);
      try {
        data.user = {
          ...data.user,
          username: data.user.phone,
          //organizationId: useUsersStore().currentUser?.organizationId,
          // walletId: useUsersStore().currentUser?.walletId,
        };
        const response = await Api.post('/carrier', data);
        this.transporteurs = response.data;
        return {
          isOk: true,
          message: '',
        };
      } catch (error: any) {
        console.log("error message",error.response)
        let errorData: IErrorData = {
          isOk: false,
          message: error.response.data.message,
        };
      
        return errorData;
      }
    },

    async update(data: any, id: string) {
      console.log('data', data);
      try {
        const response = await Api.patch(`carrier/${id}`, data);
        //this.transporteurs = response.data;
        return {
          isOk: true,
          message: '',
        };
      } catch (error: any) {
        
        let errorData: IErrorData = {
          isOk: false,
          message: error.message,
        };
       
        return errorData;
      }
    },

    async delete(id: string) {
      try {
        const response = await Api.delete(`/carrier/${id}`);
        this.transporteurs = this.transporteurs.filter((transp) => transp.id != id);
        return {
          isOk: true,
          message: '',
        };
      } catch (error: any) {
        let errorData: IErrorData = {
          isOk: false,
          message: 'Une erreur inattendue est survenue',
        };
        return errorData;
      }
    },

    async searchTransportor(search: string) {
      if (search.length === 0) {
        return [];
      }
      console.log('producteur', this.transporteurs);
      const filteredList = this.transporteurs.filter((transp: ITransporteur) => {
        return (
          transp.user.firstname.toLowerCase().includes(search.toLocaleLowerCase()) ||
          transp.user.lastname.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
          transp.user.phone.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        );
      });

      return filteredList;
    },
  },
});
