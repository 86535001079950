import { RouteRecordRaw } from "vue-router";
import Producteurs from "@/views/dashboard/acteurs/producteurs/index.vue";
import CreateProducteur from "@/views/dashboard/acteurs/producteurs/create.vue";
import ShowProducteur from "@/views/dashboard/acteurs/producteurs/show.vue";
import { RoleCode, RoleCodeList } from "@/interfaces";
const ProducteurRoutes: RouteRecordRaw[] = [
  {
    path: "producteurs",
    name: "producteurs",
    component: Producteurs,
    meta: {
      dashboard: true,
      title: "Producteurs",
      icon: "grower-white",
      requiredAuth: true,
      roles: [
        RoleCodeList.ROLE_SUPER_ADMIN,
        RoleCodeList.ROLE_CLUSTER_MANAGER,
        RoleCodeList.ROLE_ADMIN,
        //RoleCodeList.ROLE_INDUSTRIAL,
        RoleCodeList.ROLE_FARMER_ORGANIZATION,
      ] as RoleCode[],
    },
  },
  {
    path: "producteurs/create",
    name: "createProducteur",
    component: CreateProducteur,
    meta: {
      dashboard: true,
      hideNavBar: true,
      requiredAuth: true,
    },
  },
  {
    path: "producteurs/edit/:id",
    name: "editProducteur",
    component: CreateProducteur,
    meta: {
      dashboard: true,
      hideNavBar: true,
      requiredAuth: true,
    },
  },
  {
    path: "producteurs/show/:id",
    name: "showProducteur",
    component: ShowProducteur,

    meta: {
      dashboard: true,
      requiredAuth: true,
    },
  },
];

export default ProducteurRoutes;
