import { ICooperative, IProducteur, PrimaryKey } from '@/interfaces';
import { defineStore } from 'pinia';
import Api, { HttpException } from '../api';

export const useClusteurStore = defineStore('cluster', {
  state: () => {
    return {
      cooperatives: [] as ICooperative[],
      growers: [] as IProducteur[],
    };
  },

  actions:{
    async createConfig(data : any){
       
      try {
        const response = await Api.post(`clusters/create-configs`, data);
        return response.data;
      } catch (error: any) {
       
        throw new HttpException(error);
      }
    },

    async fetchAll(data?: any){
       
      try {
        const response = await Api.get(`clusters`, {params: data});
        console.log("fetchAll, response", response)
        return response.data;
      } catch (error: any) {
       
        throw new HttpException(error);
      }
    },


    async fetchAllClusters(data?: any){
       
      try {
        const response = await Api.get(`clusters/clusters`, {params: data});
        console.log("fetchAll, response", response)
        return response.data;
      } catch (error: any) {
       
        throw new HttpException(error);
      }
    },


    async findClusterConfig(managerUserId : PrimaryKey){
      try {
        const response = await Api.get(`clusters/config/${managerUserId}`);
        return response.data;
      } catch (error: any) {
       
        throw new HttpException(error);
      }
    }
     
  }
});
