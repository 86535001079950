import { Socket, io } from 'socket.io-client';

type MODE = 'development' | 'staging' | 'production';
let mode: MODE = 'development';

const SOCKET_URL: Record<MODE, string> = {
  development: 'http://localhost:3000',
  staging: 'https://agriwallet-dev.opensi.co',
  production: 'https://agriwallet.opensi.co',
};

console.log(mode, process.env.VUE_APP_ENV, 'mode');
export class SocketClient {
  private static instance: SocketClient | null = null;
  private socket: Socket | undefined = undefined;

  constructor() {
    mode = process.env.VUE_APP_ENV as MODE;
    console.log('socet is initialize in ', mode);
    if (!SocketClient.instance) {
      this.initSocketConnection();
      SocketClient.instance = this;
    }
    return SocketClient.instance;
  }

  initSocketConnection() {
    console.log(mode, 'mode');
    this.socket = io(SOCKET_URL[mode], {
      transports: ['websocket'],
      path: '/api/ws',
    });

    this.socket.on('connect', () => {
      console.log('Socket connected', this.socket?.id);
    });
  }

  get instance() {
    return this.socket;
  }
}

export const socketClient = new SocketClient();

export function addSocketListener(
  event: string,
  cb: (data: any) => void | Promise<void>,
) {
  setTimeout(() => {
    console.log(socketClient.instance, event);
    socketClient.instance?.on(event, async (data: any) => await cb(data));
  }, 3000);
}

export function joinSocketRoom(room: string) {
  setTimeout(() => {
    console.log('joining socket room: ', room);
    socketClient.instance?.emit('join-room', { room });
  }, 3000);
}

export enum WsEvents {
  NEW_INDUSTRIAL_CAMPAIGN_LINK = 'NEW_INDUSTRIAL_CAMPAIGN_LINK',
  NEW_INDUSTRIAL_TO_ADD = 'NEW_INDUSTRIAL_TO_ADD',

  NEW_INACTIVE_USER = 'NEW_INACTIVE_USER',

  NEW_GROWER_ASK_FERTILIZER = 'NEW_ASK_FERTILIZER',

  NEW_INDUSTRIAL_ASK_FERTILIZER = 'NEW_INDUSTRIAL_ASK_FERTILIZER',
}
