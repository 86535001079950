<template>
    <div data-widget-item="add-zone" class="fixed top-0 left-0 right-0 bottom-0 z-[8889] pt-[116px]">
      <div class="bg-white h-full parent px-[116px]">
        <div class="flex justify-between p-4 border-b border-[rgba(0,0,0,0.15)]">
          <span class="font-semibold text-black text-[18px]">
            {{
              existing_action === 'ADD'
                ? "Ajouter une zone de couverture"
                : "Modifier une zone de couverture"
            }}
          </span>
          <span class="inline-flex cursor-pointer" @click="$emit('close')">
            <BaseIcon icon="close"></BaseIcon>
          </span>
        </div>
  
        <div class="flex flex-row flex-wrap justify-center mt-6">
          
          <!--form-->
          <div
            class="gmapzoneform pr-6 border-r border-[rgba(0,0,0,0.15)] self-stretch max-w-[29.688rem] flex-grow"
          >
            <Form @submit="onSubmit" v-slot="{ meta }">
              <BaseInput
                label="Nom de la zone"
                rules="required"
                name="nomZone"
                v-model="form.name"
              ></BaseInput>


            
              <BaseButton
                  type="success"
                  :disabled="!meta.valid"
                  class="mt-6 px-12"
                  :loading="lazone"
              >
                  Valider
              </BaseButton>
            </Form>
          </div>
          <!-- maps -->
  
          <div class="gmapmap flex-grow px-6 max-w-[43.75rem]">
            <!-- Default position is opensi position -->
            <GMapMap
            :center="MarkerPosition"
            :zoom="12"
            map-type-id="terrain"
            :options="{
                streetViewControl: false,
            }"
            ref="gmap"
            >
            <GMapMarker
                :position="MarkerPosition"
                :clickable="true"
                :draggable="true"
                @click="GMapClicked($event)"
            />
            
            <GMapPolygon
                :key="GMapPolygonKey"
                :options="{
                strokeColor: '#006A52',
                strokeOpacity: 0.7,
                strokeWeight: 2,
                fillColor: '#006A52',
                fillOpacity: 0.35,
                }"
                :paths="MapsPaths"
            />
            </GMapMap>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { computed, ref, onMounted, onBeforeMount, toRef, toRefs, inject, watch, defineComponent } from 'vue';
  // import baseInput from '@/components/global/baseInputVee.vue';
  // import tinyCross from '@/components/global/icons/tiny-cross.vue';
  // import PrimaryButton from '@/components/global/primary-button.vue';
  
  import { Form } from 'vee-validate';
  import { useStore } from 'vuex';
  import { find } from 'lodash';
  import { useCoverZone } from '@/store/cover-zone';
  
  import { useToast } from "vue-toastification";

  import { randId } from '@/store/cover-zone';
  
  export default defineComponent({
    components: {
      // baseInput,
      // tinyCross,
      // PrimaryButton,
      Form,
    },
    props: {
      sendZone: {
        type: Object,
        default: () => Object({}),
      },
      sendAction: {
        type: String,
        default: 'ADD',
      },
    },
  
    setup(props, context) {
    //   const store = useStore();
      // const strmust = inject('$strmust');
      // const $t = inject('$t');
  
      const coverZoneStore= useCoverZone();
      const toast = useToast();
      const fetchZones = async () => {
        await coverZoneStore.getZones()
      };
  
      // const showNotif = inject('$showNotif');
      const lazone = ref(false);
  
      const gmap = ref(null);
  
      const existing_action = toRef(props, 'sendAction');
      const existing_zone = toRef(props, 'sendZone');
  
      const MapsPaths = ref([]);
      const MarkerPosition = ref({ lat: 5.3484461, lng: -4.049705 });
      const GMapPolygonKey = ref(coverZoneStore.randId());
  
      const form = ref({
        name: '',
        coordinates: [],
      });
  
  
      watch(gmap, (googleMap) => {
        if (googleMap) {
          googleMap.$mapPromise.then((map) => {
            console.log('googleMap.value', map);
          });
        }
      });
  
      const modZone = async () => {
        lazone.value = true;
  
        const res = await coverZoneStore.modifyZone({
          id: existing_zone.value.id,
          data: { ...form.value },
        });
  
      //   const res = await store.dispatch('coverzones/modifyZone', {
      //     id: existing_zone.value.id,
      //     data: { ...form.value },
      //   });
        lazone.value = false;
        if (![200, 201].includes(res.status)) {
            toast.error(res.error);
          // showError();
        } else {
          await fetchZones();
          context.emit('close');
          toast.success(`zone modifiée`)
          // showNotif({
          //   success: true,
          //   note: 'zone modifiée',
          // });
        }
      };
  
      const addZone = async () => {
        if (!(MapsPaths.value.length >= 3)){
            toast.error(response.message);
        }
          // return showNotif({
          //   error: true,
          //   note: $t('setting.coverage_areas.draw_zone'),
          // });
  
        lazone.value = true;
        const res = await coverZoneStore.addZone(form.value);
  
      //   const res = await store.dispatch('coverzones/addZone', form.value);
        lazone.value = false;
  
        if (![200, 201].includes(res.status)) {
          console.log("bad response")
          // showError();
        } else {
          await fetchZones();
          context.emit('close');
          // showNotif({
          //   success: true,
          //   note: $t('setting.coverage_areas.zone_added', { name: res.data.name }),
          // });
        }
      };
  
      const GMapClicked = (data) => {
        // console.log('GMapClicked', data, data.latLng.lat(),data.latLng.lng() );
        const lat = parseFloat(parseFloat(data.latLng.lat()).toFixed(7));
        const lng = parseFloat(parseFloat(data.latLng.lng()).toFixed(7));
        MarkerPosition.value = { lat, lng };
        GMapPolygonKey.value = coverZoneStore.randId();
  
        try {
          const fd = find(MapsPaths, { lat, lng });
          if (!fd) {
            MapsPaths.value.push({ lat, lng });
            form.value.coordinates = MapsPaths.value;
            console.log("successfully added");
          //   showNotif({
          //     success: true,
          //     note: 'success',
          //     out: 3000,
          //   });
          } else
          {
              return 'Ce point a deja été choisit'
          }
          //   return showNotif({
          //     error: true,
          //     note: '',
          //   });
        } catch (e) {
          void e;
        }
      };
  
      // const onInvalidSubmit = (err) => {
      //   console.log(err);
      // };
  
      const onSubmit = async (data) => {
        console.log('data', data, existing_action.value);
        //
        switch (existing_action.value) {
          case 'ADD':
            await addZone();
            break;
  
          case 'MOD':
            await modZone();
            break;
        }
      };
  
      onBeforeMount(() => {
        if (Object.keys(existing_zone.value).length) {
          const preForm = {
            name: existing_zone.value.name,
            coordinates: existing_zone.value.coordinates ? [...existing_zone.value.coordinates] : [],
          };
          form.value = preForm;
          MapsPaths.value = [...preForm.coordinates];
          // default marker position is opensi
          if (MapsPaths.value.length !== 0) {
            MarkerPosition.value = MapsPaths.value[0];
          }
        }
      });
  
      return {
      //   schema,
        // onInvalidSubmit,
        onSubmit,
        lazone,
        form,
        GMapClicked,
        MapsPaths,
        MarkerPosition,
        GMapPolygonKey,
        existing_action,
        gmap,
      };
    },
  });
  </script>
  
  <style scoped lang="scss">
  div[data-widget-item='add-zone'] {
    background: rgba(0, 0, 0, 0.5);
  
    .parent {
      border-radius: 24px 24px 0 0;
    }
  }
  
  @media screen and (max-width: 1000px) {
    .gmapmap {
      max-width: 700px;
      width: 100%;
      margin: 24px 0;
      padding: 0;
    }
  
    .gmapzoneform {
      padding: 0;
      margin: 0 auto;
      width: max-content;
      border: 0;
    }
  }
  </style>
  