import { RouteRecordRaw } from "vue-router";
import EmptyLayout from "@/layouts/empty.vue";
import UserIndex from "@/views/dashboard/gestions/utilisateurs/index.vue";
import { RoleCode, RoleCodeList } from "@/interfaces";
import ConfigCluster from "@/views/dashboard/gestions/cluster.vue";
import Reporting from "@/views/dashboard/gestions/reporting.vue";

const GestionRoutes: RouteRecordRaw = {
  path: "gestions",
  meta: {
    title: "Gestion",
    dashboard: true,
    submenu: true,
    requiredAuth: true,
    tag: [
      RoleCodeList.ROLE_ADMIN,
      RoleCodeList.ROLE_SUPER_ADMIN,
      RoleCodeList.ROLE_CLUSTER_MANAGER,
      RoleCodeList.ROLE_SFD,
      //RoleCodeList.ROLE_INDUSTRIAL
    ] as RoleCode[],
  },
  component: EmptyLayout,
  children: [
    {
      path: "reporting",
      name: "Reporting",
      component: Reporting,
      meta: {
        dashboard: true,
        title: "Reporting",
        icon: "user",
        requiredAuth: true,
        roles: [RoleCodeList.ROLE_SUPER_ADMIN] as RoleCode[],
      },
    },
    {
      path: "utilisateurs",
      name: "Utilisateurs",
      component: UserIndex,
      meta: {
        dashboard: true,
        title: "Utilisateurs",
        icon: "user",
        requiredAuth: true,
        roles: [
          RoleCodeList.ROLE_SUPER_ADMIN,
          RoleCodeList.ROLE_CLUSTER_MANAGER,
          RoleCodeList.ROLE_ADMIN,
          RoleCodeList.ROLE_INDUSTRIAL,
          RoleCodeList.ROLE_SFD,
        ] as RoleCode[],
      },
    },
    {
      path: "config/cluster",
      name: "configCluster",
      component: ConfigCluster,
      meta: {
        dashboard: true,
        title: "Configurer mon cluster",
        icon: "user",
        requiredAuth: true,
        roles: [RoleCodeList.ROLE_CLUSTER_MANAGER] as RoleCode[],
      },
    },
    {
      path: "config/settings",
      name: "configSettings",
      component: ConfigCluster,
      meta: {
        dashboard: true,
        title: "Paramètres",
        icon: "user",
        requiredAuth: true,
        roles: [RoleCodeList.ROLE_SFD] as RoleCode[],
      },
    },
  ],
};

export default GestionRoutes;
