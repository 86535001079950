export * from "./form";
export interface IProducteur extends Commun {
  user: IUser;
  products: Array<IField>;
  accountInfo: IAccountInfo | null;
  address: string;
}

export interface IPrestataire extends Commun {
  user: IUser;
  accountInfo: IAccountInfo | null;
}
export interface IPagination {
  page: number;
  perPage: number;
  total: number;
}
export interface IStat {}
export interface IGrower extends Commun {
  user: IUser;
  products: Array<IField>;
  accountInfo: IAccountInfo | null;
  address: string;
}
export interface ITransporteur extends Commun {
  user: IUser;
  cars: Array<IField1>;
  accountInfo: IAccountInfo | null;
  address: string;
  subCooperative: string;
}

export interface IIntrant {
  producer: string;
  numberOfBag: number;
  quantityPerceived: number;
  amount: number;
  reimbursementDate: string;
  statut: string;
  fields: Array<IField>;
}

//export type ContratStatus = 'pending' | "completed"|  "canceled" | 'outtime'

export enum ContratStatusList {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  DELIVERED = "DELIVERED",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
  OUTTIME = "OUTTIME",
}

export enum ContratType {
  LIVRAISON = "LIVRAISON",
  LIVRAISON_PARENT = "LIVRAISON_PARENT",
}

export enum DeliveryContratTypeList {
  INDIVIDUEL = "INDIVIDUEL",
  COOPERATIVE = "COOPERATIVE",
  MANY = "MANY",
}

export type ITransactionType =
  | "DEBIT"
  | "TRANSFER"
  | "REFUND"
  | "STANDARD"
  | "SUPPLY";

export type IContratStatus =
  | "INACTIVE"
  | "PENDING"
  | "CONFIRMED"
  | "DELIVERED"
  | "COMPLETED"
  | "CANCELED"
  | "OUTTIME"
  | "SUCCESS";

export type ISupplyStatus = "INIT" | "PENDING" | "SUCCESS" | "FAILED";

export type IGrowerRequestStatus = "CREATED" | "ACCEPTED" | "DONE" | "REJECTED";

export type IClusterStatus =
  | "INACTIVE"
  | "PENDING"
  | "CONFIRMED"
  | "DELIVERED"
  | "COMPLETED"
  | "CANCELED"
  | "OUTTIME"
  | "NEW"
  | "SUCCESS";

export type IPrestataireToCampagneStatus =
  | "PENDING"
  | "CONFIRMED"
  | "DELIVERED"
  | "CREATED";

export interface IContrat {
  label: string;
  contratType: string;
  operationType: DeliveryContratTypeList;
  status: IContratStatus;
}

export interface IContratLivraison extends Commun {
  amount: number;
  contract: IContrat;
  contractId: string;
  deliveryDate: string;
  grower: IProducteur;
  quantity: number;
  createdAt: Date;
}

export interface IContratTransport extends Commun {
  reference?: string;
  contract?: IContrat;
  deliveryContract: IContratLivraison;
  deliveryContractId?: PrimaryKey;
  carrier?: ITransporteur;
  carrierId?: PrimaryKey;
  vehicle?: IVehicule;
  vehicleId?: PrimaryKey;
  transportDate: Date | undefined | null;
  amount: number;
  numberOfTrips: number;
  status?: string | null;
}

export interface IVehicule extends Commun {
  carType: string;
  registration: string;
  emptyWeight: string;
}

export interface IContratIntrant extends Commun {
  reference?: string | null;
  contract?: IContrat;
  deliveryContract: IContratLivraison;
  deliveryContractId: PrimaryKey;
  numberOfBags: number;
  quantity: number;
  amount: number;
  refundDate: Date;
  status?: string | null;
}

export interface Commun {
  id?: PrimaryKey;
  createdAt?: Date;
  updatedAt?: Date;
}
export interface IPerson extends Commun {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  nip?: string;
  district: string;
  village: string;
  municipality: string;
}

export interface ILabelValue {
  label: string;
  value: any;
}

export interface IRole extends Commun {
  code: RoleCode;
  name: string;
  description: string;
}

export type RoleCode =
  | "ROLE_SUPER_ADMIN"
  | "ROLE_ADMIN"
  | "ROLE_GROWER"
  | "ROLE_CARRIER"
  | "ROLE_INDUSTRIAL"
  | "ROLE_SECRETARY"
  | "ROLE_ACCOUNTING"
  | "ROLE_DIRECTOR"
  | "ROLE_INPUT_PROVIDER"
  | "ROLE_CLUSTER_MANAGER"
  | "ROLE_FARMER_ORGANIZATION"
  | "ROLE_SFD";
export enum RoleCodeList {
  ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN",
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_GROWER = "ROLE_GROWER",
  ROLE_CARRIER = "ROLE_CARRIER",
  ROLE_INDUSTRIAL = "ROLE_INDUSTRIAL",
  ROLE_SECRETARY = "ROLE_SECRETARY",
  ROLE_ACCOUNTING = "ROLE_ACCOUNTING",
  ROLE_DIRECTOR = "ROLE_DIRECTOR",
  ROLE_INPUT_PROVIDER = "ROLE_INPUT_PROVIDER",
  ROLE_FARMER_ORGANIZATION = "ROLE_FARMER_ORGANIZATION",
  ROLE_CLUSTER_MANAGER = "ROLE_CLUSTER_MANAGER",
  ROLE_SFD = "ROLE_SFD",
}

export interface IUser extends IPerson {
  walletId: PrimaryKey;
  organizationId?: string | null;
  roles: IRole[];
  role: IRole | null;
  roleId: PrimaryKey;
  organization?: IOrganization;
  enabled?: boolean;
  wallet: IWallet;
  cluster: ICluster;
  clusterId?: PrimaryKey;
  isActive?: boolean;
  momoAccountNumber?: string | null;
  bankAccountNumber?: string | null;
}
export interface IFinanceAgregateur {
  agregateurId: string;
  amount: number;
}
export interface ICluster extends Commun {
  label: string;
  fertilizerIndex: number;
  productionIndex: number;
  unitFertilizerAmount: number;
  unitProductAmount: number;
  quotity?: number;
  from: Date;
  to: Date;
  isActive?: boolean;
  clusterFertilizers?: Array<any>;
}

export interface IDemandeIntrant extends Commun {
  industrial: string;
  fertilizerType: number;
  date: Date;
}
export interface IClusterIndustrial extends Commun {
  quantity: number;
  productionAmount: number;
  fertilizerAmount: number;
  minSupplyAmount: number;
  status: IClusterStatus;
  cluster: ICluster;
}

export interface IWallet extends Commun {
  accountNumber: string;
  balance: number;
  availableBalance: number;
  enabled?: boolean;
}

export type PrimaryKey = string | null | undefined;

export interface IOrganization extends Commun {
  denomination: string;
  ifu: string;
  reference?: string;
  organizationType: IOrganizationType;
  users?: IPerson[];
  parentId?: PrimaryKey;
  parent?: IOrganization;
  walletId?: PrimaryKey;
  representant?: IRepresentant;
}

export interface IRepresentant extends Commun {
  userId: PrimaryKey;
  user: IUser;
}
export interface ICooperative extends Commun {
  growers?: IProducteur[];
  organization: IOrganization;
  organizationId: PrimaryKey;
}

export type IOrganizationType =
  | "BANK"
  | "INDUSTRIAL"
  | "MICROFINANCE"
  | "PROVIDER"
  | "COOPERATIVE"
  | "INTERNATIONAL_TRADER";
export enum IOrganizationTypeList {
  BANK = "BANK",
  INDUSTRIAL = "INDUSTRIAL",
  MICROFINANCE = "MICROFINANCE",
  PROVIDER = "PROVIDER",
  COOPERATIVE = "COOPERATIVE",
  INTERNATIONAL_TRADER = "INTERNATIONAL_TRADER",
}
export interface IItemData {
  name: string;
  title?: string;
  description?: string;
  icon?: string;
  type?: string;
}

export interface IField1 extends Commun {
  carType: string;
  registration: string;
  emptyWeight: string;
}

export interface ITitleValue {
  title: string;
  value: any;
}
export interface IField extends Commun {
  productType: string;
  location: string;
  longitude: number;
  latitude: number;
  prevision?: number;
  engraisQuantity?: number;
  superficie?: number;
  estimatedProduction: number;
  fertilizerQuantity: number;
  areaSown: number;
  tifDate?: Date;
  plantingDate?: Date;
  district: string;
  village: string;
  borough: string;
}

export interface IAccountInfo {
  type: string;
  number: string;
}

export interface ITransaction extends Commun {
  amount: number;
  reference: string;
  status: string;
  reason?: string;
  operationType?: ITransactionType;
  destination: ITransactionUser;
  source: ITransactionUser;
}

export interface ITransactionUser {
  user: IUser;
  wallet: IWallet;
}

export interface IDashboardCard {
  title: string;
  subtitle: string;
  name: string;
  icon: string;
  primaryColor: string;
  secondaryColor: string;
}

export interface IErrorData {
  message: string;
  isOk: boolean;
}

export interface IResponseData {
  message?: string;
  isOk: boolean;
  data?: any;
}

const AGRIWALLET_ROLES = [
  {
    code: "ROLE_SUPER_ADMIN",
    name: "Super Administrateur",
    description: "Super administrateur générale de la plateforme",
  },
  {
    code: "ROLE_INDUSTRIAL",
    name: "Agrégateur",
    description: "Agrégateur",
  },
  {
    code: "ROLE_ADMIN",
    name: "Administrateur",
    description: "Administrateur de societe",
  },
  {
    code: "ROLE_GROWER",
    name: "Producteur",
    description: "Producteur d'ananas",
  },
  {
    code: "ROLE_CARRIER",
    name: "Transporteur",
    description: "Transporteur d'ananas",
  },
  {
    code: "ROLE_SECRETARY",
    name: "Secrétaire",
    description: "Secrétaire de société",
  },
  {
    code: "ROLE_ACCOUNTING",
    name: "Comptable",
    description: "Comptable de société",
  },
  {
    code: "ROLE_DIRECTOR",
    name: "Directeur",
    description: "Directeur de société",
  },
  {
    code: "ROLE_COOPERATIVE_USER",
    name: "Representant",
    description: "Administrateur de la cooperative",
  },
  {
    code: "ROLE_INPUT_PROVIDER",
    name: "Fournisseur",
    description: "Fournisseur d'intrants",
  },
];

export enum AccountType {
  MOBILE_MONEY = "MOBILE_MONEY",
  BANK = "BANK",
  CARD = "CARD",
}
