import Api, { HttpException } from "@/api";
import { ContratType, IErrorData, IResponseData, PrimaryKey } from "@/interfaces";
import { defineStore } from "pinia";


export enum IContractType {
  LIVRAISON = 'LIVRAISON',
  LIVRAISON_PARENT = 'LIVRAISON_PARENT',
  AVANCE_SUR_INTRANT = 'AVANCE_SUR_INTRANT',
  FOURNITURE_INTRANT = 'FOURNITURE_INTRANT',
  AVANCE_SUR_ANANAS = 'AVANCE_SUR_ANANAS',

  FINANCEMENT_SOCIAL = 'FINANCEMENT_SOCIAL',

}


export const useContratStore = defineStore('contrats',{
    actions : {


      async getAllContracts(id: PrimaryKey = null, type : IContractType,  config: any = {}) {
        try {
          console.log("getAllContracts",type);
          
          const response = await Api.get(
            `contracts/smarts/${id}/${type}`,
            config
          );
          return response.data;
        } catch (error: any) {
          throw new HttpException(error);
        }
      },

      async getSubContracts(id: string, config: any = {}) {
        console.log("aaa",id);
        
        try {
          const response = await Api.get(
            `contracts/sub-smarts/${id}`,
            config
          );
          return response.data;
        } catch (error: any) {
          throw new HttpException(error);
        }
      },


      async getContractPdfUrl(id : string){
        try {
          const response = await Api.get(
            `contracts/smarts-download/${id}`,
          );
          return response.data;
        } catch (error: any) {
          throw new HttpException(error);
        }
      },





      async fetchOne(id : PrimaryKey = null){
        try {
          const response = await Api.get(
            `contracts/smarts/${id}`,
            
          );
          return response.data;
        } catch (error: any) {
          throw new HttpException(error);
        }
      },





        async create(data: any){
            
            try{
             
              const response = await Api.post('/contracts',data)
              return {
                isOk : true,
                data : response.data
              }
            }
            catch(error : any){
              let errorData : IResponseData = {
                isOk : false,
                message : "Une erreur inattendue est survenue",
              } 
              switch(error.response.status){
                
              }
              return errorData
            }
            
          },
     }
})