import Api, { HttpException } from "@/api";
import { defineStore } from "pinia";
import intrantsRequests from "@/data/demandes-intrants.json";
import { authStore } from "@/store/users/auth-store";

export const useGrowerRequestStore = defineStore("growerRequestStore", {
  state: () => {
    return {
      intrantsRequests: intrantsRequests,
    };
  },
  actions: {
    async fetchAllGrowerRequestForOP(type: string, params: any = {}) {
      try {
        const userAuthStore = authStore();
        const userId = userAuthStore.auth.getUserId();
        console.log(params,"params params")
        const response = await Api.get(
          `user-cluster-grower/get-pending-requests/${type}/${userId}`,
          { 
            params : params
           }
        );
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async acceptRequest(ids: string[]) {
      try {
        const userAuthStore = authStore();
        const userId = userAuthStore.auth.getUserId();
        const response = await Api.post(`user-cluster-grower/accept-requests`, {
          ids,
        });
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async rejectRequest(data: { id: string; reason: string }[]) {
      try {
        const userAuthStore = authStore();
        const userId = userAuthStore.auth.getUserId();
        const response = await Api.post(
          `user-cluster-grower/reject-requests`,
          data
        );
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async fetchAllGrowerFinancementSocialRequestForOP() {},

    async fetchAllGrowerRequestForAggrega(type: string, params: any = {}) {
      try {
        const userAuthStore = authStore();
        const userId = userAuthStore.auth.getUserId();
        const response = await Api.get(
          `user-cluster-grower/get-industrial-requests/${type}/${userId}`,
          { params }
        );
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },
  },
});
