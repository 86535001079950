import { RouteRecordRaw } from "vue-router";
import EmptyLayout from "@/layouts/empty.vue";
import CooperativeRoutes from "./cooperative";
import ProducteurRoutes from "./producteurs";
import TransporteurRoutes from "./transporteurs";
import OPRoutes from "./ops";
import PrestatairesRoutes from "./fournisseurs-intrant.ts";
import { RoleCode, RoleCodeList } from "@/interfaces";

const ActeurRoutes: RouteRecordRaw = {
  path: "acteurs",
  name: "acteurs",
  meta: {
    title: "Acteurs",
    dashboard: true,
    submenu: true,
    requiredAuth: true,
    tag: [
      // RoleCodeList.ROLE_ADMIN,
      RoleCodeList.ROLE_SUPER_ADMIN,
      RoleCodeList.ROLE_CLUSTER_MANAGER,
      RoleCodeList.ROLE_FARMER_ORGANIZATION,

    ] as RoleCode[],
  },
  component: EmptyLayout,
  children: [
    ...OPRoutes,
    ...ProducteurRoutes,
    //  ...CooperativeRoutes,
    //...TransporteurRoutes,

    // ...PrestatairesRoutes
  ],
};

export const CooperativeActeurRoutes = {
  path: "acteurs",
  meta: {
    title: "Acteurs",
    dashboard: true,
    submenu: true,
    requiredAuth: true,
  },
  component: EmptyLayout,
  children: [...ProducteurRoutes, ...TransporteurRoutes],
};

export default ActeurRoutes;
