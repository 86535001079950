import { Api, APP_URL } from '@/api';

export type SupplyStatsResponse = {
  availableBalance: number;
  balance: number;
  lastSupplyAmount: number;
  supplies: number;
};

enum AccountType {
  MOBILE_MONEY = 'MOBILE_MONEY',
  BANK = 'BANK',
  CARD = 'CARD',
}

enum TransactionStatus {
  INIT = 'INIT',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export type SupplyResponse = {
  id: string;
  amount: number;
  accountType: AccountType;
  status: TransactionStatus;
  transactionId: string;
  reference: string;
  performedAt: string;
  paymentMethod: string;
  createdAt: string;
};

export type TransactionData = {
  reference: string;
  moyen: string;
  amount: number;
  date: string;
  statut: string;
};

export type SupplyPayload = {
  walletId: string;
  accountType?: 'BANK' | 'MOBILE_MONEY';
  bank?: 'ecobank';
  amount: number;
};

export async function getSupplyReference(data: SupplyPayload) {
  try {
    const response = await Api.post(APP_URL.API_URL + '/supply', data);
    return response.data;
  } catch (error) {
    throw new Error('Unable to get reference for supply transaction');
  }
}

export async function verifySupplyTransaction(transactionId: string) {
  try {
    const response = await Api.get(
      APP_URL.API_URL + '/supply/verify/' + transactionId,
    );
    console.log(response.data);
  } catch (error) {
    throw new Error('Unable to verify supply transaction');
  }
}

export async function loadSupplyStats(
  userId: string,
): Promise<SupplyStatsResponse> {
  try {
    const response = await Api.get(APP_URL.API_URL + '/supply/stats/' + userId);
    return response.data;
  } catch (error) {
    throw new Error('Unable to load supply transaction');
  }
}

export async function loadSupplies(
  id: string,
  type: 'momo-card' | 'bank',
  params = {},
): Promise<TransactionData[]> {
  try {
    const response = await Api.get(
      APP_URL.API_URL + '/supply/' + type + '/' + id,
      params,
    );
    const defaultData = response.data as SupplyResponse[];
    return defaultData.map((supply: SupplyResponse) => ({
      reference: supply.reference,
      moyen: supply.paymentMethod,
      amount: supply.amount,
      date: new Date(supply.createdAt).toLocaleString(),
      statut: supply.status,
    }));
  } catch (error) {
    throw new Error('Unable to load supplies');
  }
}
