import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AuthLayout from "@/layouts/auth.vue";
import DashboardLayout from "@/layouts/default.vue";
import Login from "@/views/auth/login.vue";
import DefinePassword from "@/views/auth/definePassword.vue";
import RequestPasswordForget from "@/views/auth/request-password-forget.vue";
import ChangePassword from "@/views/auth/change-password.vue";
import Register from "@/views/auth/register.vue";
import TableauBord from "@/views/dashboard/index.vue";

import Wallet from "@/views/dashboard/wallet/index.vue";

import CampagneRoutes from "./../router/dashboard/campagne";

import SitesRoutes from "./dashboard/sites";

import DemandeIntrantsRoutes from "./../router/dashboard/demande-intrants";

// import AdminDashBoard from '@/views/admin/index.vue';
// import CampagneIndex from '@/views/admin/campaign/index.vue';
// import CampagneCreate from '@/views/admin/campaign/create.vue';

import Profile from "@/views/dashboard/profile/index.vue";

import ContratsRoutes from "@/router/dashboard/contract";
import ActeurRoutes, { CooperativeActeurRoutes } from "./dashboard/acteurs";
import PrestatairesRoutes from "./dashboard/prestataires";

import TransactionRoutes from "@/router/dashboard/transactions";
import GestionRoutes from "./dashboard/gestions";
import { useUsersStore } from "@/store/users/user-store";
import jwt_decode from "jwt-decode";
import { authStore } from "@/store/users/auth-store";
import Auth from "@/auth";
import { RoleCode, RoleCodeList } from "@/interfaces";
import DemandeFinancementRoutes from "./dashboard/demande-financement";

import PdfViewer from '@/views/pdf-viewer.vue'

export const Routes: Array<RouteRecordRaw> = [
  {
    path: "/auth",
    component: AuthLayout,
    children: [
      {
        path: "login",
        name: "login",
        component: Login,
      },
      {
        path: "/",
        name: "firstLogin",
        component: Login,
      },
      {
        path: "/signup-invitation/",
        name: "definePassword",
        component: DefinePassword,
      },
      {
        path: "/password-forget",
        name: "request-password-forget",
        component: RequestPasswordForget,
      },
      {
        path: "/change-password",
        name: "change-password",
        component: ChangePassword,
      },
      {
        path: "/register",
        name: "register",
        component: Register,
      },
    ],
  },

  {
    path: "/pdf/viewer/:link",
    name: "pdfViewer",
    component: PdfViewer,
  },

 

  {
    path: "/",
    component: DashboardLayout,
    meta: {
      requiredAuth: true,
      title: "Demandes",
      submenu: true,
      //  tag: [RoleCodeList.ROLE_INDUSTRIAL] as RoleCode[] //a decommenter
      tag: [RoleCodeList.ROLE_INPUT_PROVIDER] as RoleCode[],
    },
    children: [...DemandeIntrantsRoutes],
  },

  {
    path: "/",
    component: DashboardLayout,
    meta: {
      requiredAuth: true,
      title: "Demandes",
      submenu: true,
      //  tag: [RoleCodeList.ROLE_INDUSTRIAL] as RoleCode[] //a decommenter
      tag: [RoleCodeList.ROLE_FARMER_ORGANIZATION] as RoleCode[],
    },
    children: [...DemandeFinancementRoutes],
  },

  {
    path: "/dashboard",
    component: DashboardLayout,
    // name: 'dashboard',
    meta: {
      requiredAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "tableauBord",
        component: TableauBord,
      },
      
      {
        path: "/wallet",
        name: "monWallet",
        component: Wallet,
      },
      {
        path: "/profile",
        name: "profile",
        component: Profile,
        meta: {
          requiredAuth: true,
        },
      },
      CampagneRoutes,
      SitesRoutes,
      TransactionRoutes,
      PrestatairesRoutes,
      ActeurRoutes,
      ContratsRoutes,
      GestionRoutes,
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: Routes,
});

router.beforeEach(async (to, from, next) => {
  const requiredAuthentication = to.matched.some(
    (record) => record.meta.requiredAuth
  );
  // target route required authentication
  if (requiredAuthentication) {
    const useAuthStore = authStore();

    // no current auth is not authenticated, first try to authenticate user with storage token
    if (!useAuthStore.getAuth.isAuthenticated()) {
      const token = localStorage.getItem("agriwallet.access.token") as string;

      if (token) {
        let tokenInfos: {
          sub: number;
          username: string;
        } = {
          sub: 0,
          username: "",
        };
        try {
          tokenInfos = jwt_decode(token);
        } catch (error) {
          console.log("tokenInfos error ---> ", error);
        }
        // console.log('tokenInfos ---> ', tokenInfos);

        if (!useUsersStore().getCurrentUser) console.log("from", from.name);

        if (
          !useUsersStore().getCurrentUser ||
          from.name == "login" ||
          from.name == "firstLogin"
        ) {
          const user = await useUsersStore().findOne(tokenInfos.sub, true);

          console.log("currentAuth ---> ", user);

          authStore().getAuth.setAuth({ user });
        }
      } else {
        useAuthStore.auth = new Auth(null);
      }
    }

    if (useAuthStore.getAuth.isAuthenticated()) {
      // user is authenticated
      // TODO: handle user route with role and permissions
      next();
    } else {
      // query: {redirect: to.fullPath}
      next({ name: "login" });
    }
  } else {
    next();
  }
});

export default router;

export function getAuthRoutes(auth: Auth) {
  // const routes: RouteRecordRaw[] = []
  const dashboard: RouteRecordRaw = {
    path: "/dashboard",
    component: DashboardLayout,
    name: "dashboard",
    children: buildChildrenRoutes(auth),
  };
  return dashboard;
}

function buildChildrenRoutes(auth: Auth) {
  let children: RouteRecordRaw[] = [];
  if (auth.isIndustrial()) {
    children = [
      TransactionRoutes,
      ActeurRoutes,
      ContratsRoutes,
      // getAccessibleRoute(TransactionRoutes, ['paiementsEffectues', 'paiementShow', 'approvisionnements']),
      // getAccessibleRoute(ActeurRoutes, ['producteurs', 'createProducteur', 'editProducteur', 'showProducteur']),
      // getAccessibleRoute(ContratsRoutes, ['producteurs', 'createProducteur', 'editProducteur', 'showProducteur']),
    ];
  }

  if (auth.isCooperative()) {
    children = [
      TransactionRoutes,
      CooperativeActeurRoutes,
      ContratsRoutes,
      GestionRoutes,
    ];
  }

  return children;
}

function getAccessibleRoute(
  route: RouteRecordRaw,
  childrenName: string[]
): RouteRecordRaw {
  let filteredRoute: RouteRecordRaw = route;

  if (childrenName.length === 1 && childrenName[0] === "*") {
    filteredRoute.children = route.children || [];
  }

  return filteredRoute;
}
