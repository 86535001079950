import { RouteRecordRaw } from 'vue-router';

import MagasinIndex from '@/views/dashboard/magasins/index.vue';

import { RoleCode, RoleCodeList } from '@/interfaces';
import EmptyLayout from '@/layouts/empty.vue'

const SitesRoutes: RouteRecordRaw = 

{
  path: "industriel/mes-magasins",
  component: EmptyLayout,
  meta: {
    title: "Mes magasins",
    submenu: true,
    requiredAuth: true,
    tag: [RoleCodeList.ROLE_INDUSTRIAL] as RoleCode[],
  },
  children: [
   
  {
    path: '',
    name: 'mesites',
      component: MagasinIndex,
    meta: {
      dashboard: true,
      title: "Magasins",
      icon : 'campagneSC',
      requiredAuth: true,
      roles: [
        RoleCodeList.ROLE_INDUSTRIAL
      ] as RoleCode[],
    },
  },
  ]
}






export default SitesRoutes;
