import { defineStore } from 'pinia';
import { IErrorData, IPrestataire, PrimaryKey } from '@/interfaces/index';
import Api, { HttpException } from '@/api';
import { useUsersStore } from './users/user-store';
import data from '@/data';

export const usePrestataire = defineStore('prestataireStore', {
  state: () => {
    return {
      prestataires: [] as IPrestataire[],
    };
  },

  actions: {
    async fetchAll(params: any = {}) {
      const response = await Api.get('input-provider', { params });
      this.prestataires = response.data;
    },

    async fetchOne(id: string) {
      try {
        const response = await Api.get(`input-provider/${id}`);

        console.log(response.data, 'response data');
        return response.data;
      } catch (error: any) {}
    },

    async create(data: any) {
     try {
       
        const response = await Api.post('/input-provider', data);
        this.prestataires = response.data;
        return {
          isOk: true,
          message: '',
        };
      } catch (error: any) {
        console.log("error message",error.response)
        let errorData: IErrorData = {
          isOk: false,
          message: error.response.data.message,
        };
      
        return errorData;
      }
    },

    async update(data: any, id: string) {
      try {
        const response = await Api.patch(`input-provider/${id}`, data);
        return {
          isOk: true,
          message: '',
        };
      } catch (error: any) {
        let errorData: IErrorData = {
          isOk: false,
          message: error.message,
        };
        return errorData;
      }
    },

    async delete(id: string) {
      try {
        console.log("grower before before",this.prestataires)
        const response = await Api.delete(`/input-provider/${id}`);
        console.log("grower before",this.prestataires)
        this.prestataires = this.prestataires.filter((prod) => prod.id != id);
        console.log("grower after",this.prestataires)
        return {
          isOk: true,
          message: '',
        };
      } catch (error: any) {
        let errorData: IErrorData = {
          isOk: false,
          message: 'Une erreur inattendue est survenue',
        };
        return errorData;
      }
    },
    async searchProductor(search: string) {
      if (search.length === 0) {
        return [];
      }
      console.log('producteur', this.prestataires);
      const filteredList = this.prestataires.filter((prod: IPrestataire) => {
        return (
          prod.user.firstname.toLowerCase().includes(search.toLocaleLowerCase()) ||
          prod.user.lastname.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
          prod.user.phone.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        );
      });

      return filteredList;
    },
   
    async fetchPrestataires( config: any = {}) : Promise<IPrestataire[]> {
      try {
        const response = await Api.get(`/input-provider`, config);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },


    async fetchPrestatairesByCluster( userId : PrimaryKey, config: any = {}) : Promise<IPrestataire[]> {
      try {
        const response = await Api.get(`/input-provider/cluster/${userId}`, config);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

  }
});
