
export function YFormatter(value: string | number) {
  let sizing = value;
  //
  if (value === Infinity) sizing = '';
  else if (value >= 1000000000) sizing = Math.round(Number(value) / 1073741824) + 'Md';
  else if (value >= 1000000) sizing = Math.round(Number(value) / 1048576) + 'M';
  else if (value >= 1000) sizing = Math.round(Number(value) / 1000) + 'K';
  //
  return sizing;
}

