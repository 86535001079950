import {
  IUser,
  IOrganization,
  PrimaryKey,
  IWallet,
  IOrganizationTypeList,
  RoleCode,
} from "./interfaces";

export default class Auth {
  private id: PrimaryKey = null;
  private user: IUser | null = null;
  private organization: IOrganization | null = null;
  private organizationId: PrimaryKey = null;
  private walletId: PrimaryKey;
  private wallet: IWallet | null = null;
  private balance: number = 0;
  private authenticated: boolean = false;
  constructor(data: any) {
    this.init(data);
  }

  private init(data: any) {
    this.setAuth(data);
  }
  setAuth(data: any) {
    console.log("data", data);

    if (data) {
      this.user = data.user;
      this.authenticated = true;
      this.id = data.id;
    } else {
      this.authenticated = false;
    }
    if (this.user) {
      if (this.user.organization) {
        this.organization = this.user.organization;
        this.organizationId = this.user.organization.id;
      }

      this.wallet = this.user.wallet;
      this.walletId = this.user.walletId;
      this.balance = this.user.wallet.balance;
    }
  }

  isAuthenticated() {
    return this.authenticated;
  }

  isOrganization() {
    return this.organization !== undefined && this.organization !== null;
  }

  isIndustrial() {
    return (
      this.isOrganization() &&
      this.organization?.organizationType === IOrganizationTypeList.INDUSTRIAL
    );
  }

  isProvider() {
    return (
      this.isOrganization() &&
      this.organization?.organizationType === IOrganizationTypeList.PROVIDER
    );
  }
  isCooperative() {
    return (
      this.isOrganization() &&
      this.organization?.organizationType === IOrganizationTypeList.COOPERATIVE
    );
  }

  isSuperAdmin() {
    return this.hasRole("ROLE_SUPER_ADMIN");
  }

  isClusterManager() {
    return this.hasRole("ROLE_CLUSTER_MANAGER");
  }

  isSFD() {
    return this.hasRole("ROLE_SFD");
  }
  hasRole(code: RoleCode) {
    let hasRole = false;
    if (this.user) {
      hasRole = this.user.role?.code === code;
    }
    return hasRole;
  }

  getRoleCode(): RoleCode | undefined {
    return this.user?.role?.code;
  }

  getWalletId(): PrimaryKey {
    return this.walletId;
  }

  getWallet(): IWallet | null {
    return this.wallet;
  }

  getOrganizationId(): PrimaryKey {
    return this.organizationId;
  }

  getClusterId(): PrimaryKey {
    console.log("this.user", this.user)
    return this.user?.clusterId;
  }

  getOrganization(): IOrganization | null {
    return this.organization;
  }

  getUser(): IUser | null {
    return this.user;
  }

  getUserId(): PrimaryKey {
    return this.user?.id;
  }

  getProfileName(): string {
    let name = "";
    if (this.user) {
      name = `${this.user.firstname} ${this.user.lastname}`;
    }
    return name;
  }

  getOrganizationName(): string {
    let name = "";

    if (this.organization) {
      name = this.organization.denomination;
    }
    return name;
  }

  setBalance(amount: number) {
    this.balance = amount;
  }

  getBalance() {
    return this.balance;
  }
}
