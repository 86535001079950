import {  IContratLivraison, IResponseData } from "@/interfaces";
import { defineStore } from "pinia";
import allContrats from "@/data/contrats-livraisons.json";
import Api, { HttpException } from "@/api";

export const useContratLivraisonStore = defineStore("contratsLivraisons", {
  state: () => {
    return {
      contrats: [] as Array<IContratLivraison>,
    };
  },

  actions: {
    async create(data: any) {
      try {
        const response = await Api.post("/contracts/delivery-contract", data);
        return response.data
      } catch (error: any) {
        const err = new HttpException(error)
        throw err
      }
    },
    async fetchAll() {
      const response = await Api.get("contracts/delivery-contract");
      console.log("response",response.data)
      this.contrats = response.data;
      console.log("data", this.contrats);
    },
    async fetchOne(id: string) {
      try {
        const response = await Api.get(`contracts/delivery-contract/${id}`);

        console.log(response.data, 'response data');
        return response.data;
      } catch (error: any) {}
    },

    async find(params: any = {}) {
      try {
        const response = await Api.get('contracts/delivery-contract', { params });
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },
  },

    getters : {
        getAllContrats :  (state) => {
            return state.contrats
        },
        getContrat: (state) => {
            return (contratRef: string)=>
              state.contrats.find(
                (contrat) =>
                contrat.contract.label == contratRef
              );
          },
    }
}
)
