import Api, { HttpException } from "@/api";
import { ITransaction, PrimaryKey } from "@/interfaces";
import { AxiosRequestConfig } from "axios";
import { defineStore } from "pinia";

export const useTransaction = defineStore("transactionStore", {
  state: () => {
    return {
      transactions: [],
      currentTransaction: null as ITransaction | null,
    };
  },

  getters: {
    getCurrentTransaction: (state) => {
      return (transaction: ITransaction | null = null) => {
        if (transaction) {
          state.currentTransaction = transaction;
        }
        return state.currentTransaction;
      };
    },
  },

  actions: {
    async find(params: any = {}) {
      try {
        const response = await Api.get("transaction", { params });
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },
    async findOne(id: PrimaryKey, params: any = {}) {
      try {
        const response = await Api.get(`transaction/${id}`, { params });
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },
    async update(id: PrimaryKey, data: any) {
      try {
        const response = await Api.put(`transaction/${id}`, data);
        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async getSupply(config?: AxiosRequestConfig) {
      try {
        const response = await Api.get("supply", config);

        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async validateSupply(reference: string, config?: AxiosRequestConfig) {
      try {
        const response = await Api.get(
          `supply/validate-bank-supply/${reference}`,
          config
        );

        return response.data;
      } catch (error: any) {
        throw new HttpException(error);
      }
    },

    async downloadApprovisionnement(
      id: PrimaryKey,
      config?: AxiosRequestConfig
    ) {
      open(
        Api.defaults.baseURL + `/farmer-organization/download/${id}`,
        "_blank"
      );
    },
  },
});
